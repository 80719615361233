/* LandingPage.css */

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 20px;
}
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 800px; /* Adjust the max-width value as needed */
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 95%;
  }
  
  
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid #dddddd;
  }
  
  button {
    background-color: #245dc1;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #1d4b9e;
  }
  .banner {
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
    margin-bottom: 20px; /* Add margin-bottom to create space between banner and form */
  }
  
  .banner img {
    width: 200px;
  }
  
  .banner h1 {
    margin-top: 20px;
    font-size: 28px;
    color: #333333;
  }
  
  .banner p {
    font-size: 18px;
    color: #666666;
  }
  .centered {
    text-align: center;
  }
  .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }  
  